import { FC, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'

import { TProfitSellItem } from '@/api/profit-loss/types'
import { handleROIColor } from '@/components/token-history-table/libs/helpers'
import tableStyles from '@/components/token-history-table/styles.module.scss'
import { Icon, TokenRedirect, Typography } from '@/libs/common'
import { IconWithTooltip } from '@/libs/common/icon-with-tooltip'
import { IconName } from '@/libs/enums'
import { dateFromObjectId, formatNumber } from '@/libs/helper'

import styles from './styles.module.scss'

type TProps = {
  row: TProfitSellItem
  index: number
}

const Row: FC<TProps> = ({ row, index }) => {
  const [isOpen, setIsOpen] = useState(false)
  const date = dateFromObjectId(row.panel._id).toISOString().split('T')[0]

  return (
    <>
      <TableRow
        className={cn(styles.tableRow, {
          [styles.backgroundEven]: index % 2 !== 0,
          [styles.backgroundOdd]: index % 2 === 0,
        })}
      >
        <TableCell className={cn(styles.cell, styles.rowCell)}>
          <IconButton onClick={() => setIsOpen((state) => !state)}>
            {isOpen ? (
              <KeyboardArrowUpIcon className={styles.icon} />
            ) : (
              <KeyboardArrowDownIcon className={styles.icon} />
            )}
          </IconButton>
        </TableCell>

        <TableCell
          className={cn(styles.cell, styles.tableRow, styles.paddingZero)}
          component="th"
          scope="row"
        >
          {date}
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow)} align="left">
          <Box display="flex" flexDirection="column">
            <TokenRedirect address={row.panel.token_address}>
              {row.panel_data.tokenMetadata.name || 'Token'}
            </TokenRedirect>
          </Box>
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow, styles.honeypot)}>
          {row.panel_data.simulation?.data.honeypot && (
            <IconWithTooltip
              variant="div"
              className={styles.redText}
              iconName={IconName.TRIANGLE}
              messageClassName={styles.tooltipWrapper}
              tooltip={
                <div className={styles.tooltip}>
                  <span className={styles.redText}>
                    <Icon name={IconName.TRIANGLE} />
                  </span>
                  <Typography textColor="light-grey">Honeypot</Typography>
                </div>
              }
            />
          )}
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow)} align="right">
          {formatNumber(row.panel_data.boughtAmountEth).formatted} ETH
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow, styles.revenueCeil)} align="right">
          {formatNumber(row.panel_data.revenue).formatted} ETH
        </TableCell>

        <TableCell
          className={cn(
            styles.cell,
            styles.tableRow,
            styles.colorGreen,
            styles[handleROIColor(row.panel_data.realizedROI)],
          )}
          align="right"
        >
          <Box display="flex" flexDirection="column">
            <div>
              {formatNumber(row.panel_data.soldEth).formatted}ETH / $
              {formatNumber(row.panel_data.realizedUSD).formatted}
            </div>
            <div>
              ROI{' '}
              {row.panel_data.realizedROI === null ? (
                '-'
              ) : (
                <>{formatNumber(row.panel_data.realizedROI).formatted}%</>
              )}
            </div>
          </Box>
        </TableCell>

        <TableCell
          className={cn(
            styles.cell,
            styles.tableRow,
            styles.colorGreen,
            styles[handleROIColor(row.panel_data.potentialROI)],
          )}
          align="right"
        >
          <Box display="flex" flexDirection="column">
            <div>
              {formatNumber(row.panel_data.worth).formatted}ETH / $
              {formatNumber(row.panel_data.potentialUSD).formatted}
            </div>
            <div>
              ROI{' '}
              {row.panel_data.potentialROI === null ? (
                '-'
              ) : (
                <>{formatNumber(row.panel_data.potentialROI).formatted}%</>
              )}
            </div>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow className={styles.backgroundInner}>
        <TableCell
          className={cn(styles.cell, styles.cellWrapper)}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box width={'100%'}>
              <Table classes={styles} size="small" aria-label="purchases">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell
                      className={cn(styles.cell, styles.paddingZero)}
                      align="right"
                    ></TableCell>

                    <TableCell
                      className={cn(styles.cell, styles.paddingZero)}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.panel_data.seperate.data.map((wallet, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={cn(styles.cell, tableStyles.arrowCeil)}
                          component="th"
                          scope="row"
                        ></TableCell>

                        <TableCell
                          className={cn(styles.cell, styles.time)}
                          component="th"
                          scope="row"
                        >
                          {wallet.publicWallet.name}
                        </TableCell>

                        <TableCell className={cn(styles.cell, styles.tokenCeilSm)}></TableCell>

                        <TableCell
                          className={cn(styles.cell, tableStyles.arrowCeil)}
                          component="th"
                          scope="row"
                        ></TableCell>

                        <TableCell className={cn(styles.cell, styles.spentCeilSm)} align="right">
                          {formatNumber(wallet.boughtAmountEth).formatted} ETH
                        </TableCell>

                        <TableCell className={cn(styles.cell, styles.revenueCeilSm)} align="right">
                          {formatNumber(wallet.revenue).formatted} ETH
                        </TableCell>

                        <TableCell
                          className={cn(
                            styles.cell,
                            styles.colorGreen,
                            styles.rlCeilSm,
                            styles[handleROIColor(wallet.realizedROI)],
                          )}
                          align="right"
                        >
                          <Box display="flex" flexDirection="column">
                            <div>
                              {formatNumber(wallet.soldEth).formatted}ETH / $
                              {formatNumber(wallet.realizedUSD).formatted}
                            </div>
                            <div>
                              ROI{' '}
                              {wallet.realizedROI === null ? (
                                '-'
                              ) : (
                                <>{formatNumber(wallet.realizedROI).formatted}%</>
                              )}
                            </div>
                          </Box>
                        </TableCell>

                        <TableCell
                          className={cn(
                            styles.cell,
                            styles.colorGreen,
                            styles.plCeilSm,
                            styles.paddingRight,
                            styles[handleROIColor(wallet.potentialROI)],
                          )}
                          align="right"
                        >
                          <Box display="flex" flexDirection="column">
                            <div>
                              {formatNumber(wallet.worth).formatted}ETH / $
                              {formatNumber(wallet.potentialUSD).formatted}
                            </div>
                            <div>
                              ROI{' '}
                              {wallet.potentialROI == null ? (
                                '-'
                              ) : (
                                <>{formatNumber(wallet.potentialROI).formatted}%</>
                              )}
                            </div>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { Row }
