import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteWallet, setWalletAsDefault } from '@/api/wallet'
import { Spinner } from '@/components/spinner/spinner'
import { useAppMode } from '@/hooks/useAppMode'
import {
  Button,
  ButtonIcon,
  ButtonLink,
  Icon,
  Popover,
  type TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  wallet: TUserWallet
}

const WalletItem: FC<TProps> = ({ wallet }) => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.userData)
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const setMainWallet = async (wallet: TUserWallet) => {
    if (!user) return
    try {
      setIsLoading(true)

      await setWalletAsDefault(user._id, wallet.public_key)
      await dispatch(fetchUserWallets(user._id)).unwrap()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSelectedWallet = async () => {
    if (!user) return
    try {
      setIsLoading(true)

      await deleteWallet(user._id, wallet.public_key)
      await dispatch(fetchUserWallets(user._id)).unwrap()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.element}>
      <div className={styles.info}>
        <div>
          <Typography variant="body2" className={styles.title}>
            {wallet.name}
          </Typography>
          <Typography variant="body2">{hideWalletAddress(wallet.public_key)}</Typography>
        </div>
        {wallet.isDefault ? (
          <Typography className={styles.label} variant="body2">
            Main
          </Typography>
        ) : isLoading ? (
          <Spinner size={SpinnerSize.SMALL} />
        ) : (
          <Typography
            className={styles.button}
            variant="body2"
            onClick={() => setMainWallet(wallet)}
          >
            Set as main
          </Typography>
        )}
      </div>
      <div className={styles.controls}>
        <ButtonIcon
          tooltipTitle="Edit"
          icon={IconName.EDIT}
          className={styles.link}
          onClick={() =>
            navigate(
              `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.EDIT_WALLET_NAME}/${wallet.id}`,
            )
          }
        />

        <ButtonIcon
          tooltipTitle="Delete"
          icon={IconName.DELETE}
          className={styles.delete}
          onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
        />

        <Popover
          className={styles.popover}
          popoverState={deletePopup}
          onChangeVisible={setDeletePopup}
        >
          <Typography variant="body2" className={styles.title}>
            Confirm Delete
          </Typography>
          <Typography variant="body2">
            Are you sure you want to delete {wallet.name} wallet?
          </Typography>
          <div className={styles.popoverButtons}>
            <ButtonIcon
              onClick={() => {
                setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
              }}
            >
              <Typography variant="body2" className={styles.cancel}>
                Cancel
              </Typography>
            </ButtonIcon>
            <Button
              className={styles.confirm}
              styleVariant="red"
              onClick={() => {
                deleteSelectedWallet()
                setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
              }}
            >
              Delete
            </Button>
          </div>
        </Popover>

        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.WALLET_DETAILS}/${wallet.public_key}`}
          className={styles.link}
        >
          <Icon name={IconName.CHEVRON_RIGHT} />
        </ButtonLink>
      </div>
    </div>
  )
}

export { WalletItem }
